import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import apiRequest from 'utilities/api';
import { useStores } from 'utilities/useStores';


function renderCallDisposition(data) {
	function renderCallTable(call) {
		return (
			<Fragment>
				<thead>
					<tr>
						<th style={{ textAlign: 'center', backgroundColor: '#E6EEEE' }} colSpan={2}>CallID: { call?.CallID }</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>CallType:</td>
						<td>{ call?.CallType }</td>
					</tr>
					<tr>
						<td>CallID:</td>
						<td>{ call?.CallID }</td>
					</tr>
					<tr>
						<td>CallType:</td>
						<td>{ call?.CallType }</td>
					</tr>
					<tr>
						<td>InvNmbr:</td>
						<td>{ call?.InvNmbr }</td>
					</tr>
					<tr>
						<td>NumberCalled:</td>
						<td>{ call?.NumberCalled }</td>
					</tr>
					<tr>
						<td>TwiResponse:</td>
						<td>{ call?.TwiResponse }</td>
					</tr>
					<tr>
						<td>TwilioID:</td>
						<td>{ call?.TwilioID }</td>
					</tr>
					<tr>
						<td>UserID:</td>
						<td>{ call?.UserID }</td>
					</tr>
					<tr>
						<td>WhenCalled:</td>
						<td>{ call?.WhenCalled }</td>
					</tr>
					<tr>
						<td>duration:</td>
						<td>{ call?.duration }</td>
					</tr>
					<tr>
						<td>redirect:</td>
						<td>{ call?.redirect }</td>
					</tr>
				</tbody>
			</Fragment>
		);
	}
	return data.map((call, key) => {
		return (
			<Table striped size="sm" key={ key } style={{ margin: '10px auto', width: '250px', border: '2px solid #000' }}>
				{ renderCallTable(call) }
			</Table>
		);
	});

}

export default function RobocallingDisposition() {

	useEffect(() => {
		initial();
	}, []);
	const [callData, setCallData] = useState([]);

	const { authStore } = useStores();
	async function initial() {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const urlParams = Object.fromEntries(urlSearchParams.entries());
		const { id, Invc } = urlParams;
		console.log(authStore?.values?.user);
		const result = await apiRequest({ endpoint: 'v3/backend/getRoboCallLog', parameters: { business_id: id, backend_key: 'rossprog', invoice_number: Invc } });

		setCallData(result?.data);
	}

	return renderCallDisposition(callData);
}